<template>
  <div class="vx-row">
    <data-view-sidebar :isSidebarActive="addNewDataSidebar" @closeSidebar="toggleDataSidebar" :data="sidebarData"
                       :org="org" :type="type" />
    <div class="vx-col w-full mb-5">
      <vx-card class="mb-4">
        <marquee-text class="pl-24" :repeat="20" :duration="50">
          <h3>{{notice}}</h3>
        </marquee-text>
      </vx-card>
      <vx-card title="บัญชี/รอบบันทึกค่าบริการ">
        <div class="vx-row">
          <div class="vx-col md:w-1/3 sm:w-1/3 xs:w-1/1 w-full">
            <div class="vx-row">
              <div class="vx-col sm:w-1/3 w-full">
                <span>รหัสรอบบิล:</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <v-select :options="billConfigs" @input="setBillConfig" :clearable="false" v-model="selectedBillConfig"
                          class="mb-4 md:mb-0"/>
              </div>
            </div>
          </div>
          <div class="vx-col md:w-1/3 sm:w-1/3 xs:w-1/1 w-full">
            <div class="vx-row">
              <div class="vx-col sm:w-1/3 w-full">
                <span>ปีงบประมาณ:</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <vs-input class="w-full" v-model="year"/>
              </div>
            </div>
          </div>
          <div class="vx-col md:w-1/3 sm:w-1/3 xs:w-1/1 w-full">
            <div class="vx-row">
              <div class="vx-col sm:w-1/3 w-full">
                <span>รอบที่:</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <vs-input class="w-full" v-model="yearTh"/>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
    <div class="vx-col w-full mb-base">
      <vs-tabs alignment="fixed" v-model="tabActive">
        <vs-tab label="ตรวจสอบค่าน้ำสูงผิดปกติ" class="p-0">
          <vx-card class="mb-8 mt-6">
            <div class="vx-row">
              <div class="vx-col md:w-1/2 w-full mb-4">
                <h4>เลือกรายการ</h4>
              </div>
              <div class="vx-col md:w-1/2 w-full text-right">
                <vs-button color="primary" type="filled" @click="submitForm()">ตรวจสอบ</vs-button>
              </div>
            </div>
            <div class="vx-row mb-5 mt-5">
              <div class="vx-col sm:w-1/3 xs:w-1/1 w-full">
                <vs-radio v-model="radioBtn" vs-value="01">
                  <span>เส้นทางจัดเก็บ:</span>
                </vs-radio>
                <v-select class="area-radio1" :options="routes" :clearable="false" v-model="selectedRouteTab0Btn01"
                          style="width: 200px; display: inline-block; margin-left: 10px" :disabled="disable01"/>
              </div>
              <div class="vx-col sm:w-1/3 xs:w-1/1 w-full">
                <div class="vx-row">
                  <div class="vx-col sm:w-2/4">
                    <span>ปริมาณใช้น้ำตั้งแต่:</span>
                  </div>
                  <div class="vx-col sm:w-1/4 w-full mr-4">
                    <vs-input class="input-unit" type="number" v-model="unit" :disabled="disable01"/>
                  </div>
                  <div class="vx-col sm:w-1/4 w-full ml-5">
                    <span>หน่วย</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="vx-row mb-5 mt-5">
              <div class="vx-col sm:w-1/3 w-full">
                <vs-radio v-model="radioBtn" vs-value="02">
                  <span>เลขทะเบียนผู้ใช้น้ำ</span>
                </vs-radio>
                <vs-input v-model="inputNumber" style="width: 170px; display: inline-block; margin-left: 10px"
                          :disabled="disable02"/>
              </div>
              <div class="vx-col sm:w-1/3 xs:w-1/1 w-full">
                <div class="vx-row">
                  <div class="vx-col sm:w-1/3 w-full">
                    <span>เส้นทางจัดเก็บ:</span>
                  </div>
                  <div class="vx-col sm:w-2/3 w-full">
                    <v-select class="area-radio2" :options="routes" :clearable="false" v-model="selectedRouteTab0Btn02"
                              :disabled="disable02"/>
                  </div>
                </div>
              </div>
              <div class="vx-col sm:w-1/3 xs:w-1/1 w-full">
                <span class="mr-5">ปริมาณใช้น้ำตั้งแต่ 0 หน่วย</span>
              </div>
            </div>
            <div class="vx-row">
              <div class="vx-col sm:w-1/1 w-full text-right mt-5 mb-5">
                <PDFBillReport
                    v-if="printable"
                    :yearTh="yearTh"
                    :bookLists="users"
                    :imgBase64="imgSignBase64"
                    :imgLogoBase64="imgLogoBase64"
                    :org="org"
                    class="mr-4"
                  />
                <PDFReport :tab="'0'" :org="org" :unit="unit" :billConfig="selectedBillConfig"
                           :route="selectedRouteTab0" :user="users"/>
                <CheckPrapaMeterReport class="ml-3 mr-3" :billConfig="selectedBillConfig" :area="selectedRouteTab0"
                                       :org="org"/>
                <vs-button @click="getBookListsExcel()" color="primary" type="border" class="mb-4 md:mb-0">
                  รายงานตรวจสอบจดมิเตอร์ EXCEL
                </vs-button>
              </div>
            </div>
            <vs-table noDataText="ไม่พบข้อมูล" :data="users" class="table-custom mt-5">
              <template slot="thead">
                <vs-th>ลำดับที่</vs-th>
                <vs-th>รหัสสมาชิก</vs-th>
                <vs-th>ชื่อสกุล</vs-th>
                <vs-th>พื้นที่</vs-th>
                <vs-th>จดก่อน</vs-th>
                <vs-th>จดใหม่</vs-th>
                <vs-th>วันจดก่อน</vs-th>
                <vs-th>วันจดใหม่</vs-th>
                <vs-th>หน่วย</vs-th>
                <vs-th>ค่าน้ำรวม</vs-th>
                <vs-th>คำสั่ง</vs-th>
              </template>
              <template slot-scope="{data}">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                  <vs-td>
                    <p class="product-name font-medium">{{ indextr + 1 }}</p>
                  </vs-td>
                  <vs-td>
                    <p class="product-name font-medium">{{ tr.B03 }}</p>
                  </vs-td>
                  <vs-td>
                    <p class="product-name font-medium">{{ tr.M04 }}</p>
                  </vs-td>
                  <vs-td>
                    <p class="product-name font-medium">{{ selectedRouteTab0.label }}</p>
                  </vs-td>
                  <vs-td>
                    <p class="product-name font-medium">{{ tr.B18 }}</p>
                  </vs-td>
                  <vs-td>
                    <p class="product-name font-medium">{{ tr.B20 }}</p>
                  </vs-td>
                  <vs-td>
                    <p class="product-name font-medium">{{ tr.B17 }}</p>
                  </vs-td>
                  <vs-td>
                    <p class="product-name font-medium">{{ tr.B19 }}</p>
                  </vs-td>
                  <vs-td>
                    <p class="product-name font-medium">{{ tr.B21 }}</p>
                  </vs-td>
                  <vs-td>
                    <p class="product-name font-medium">{{ setSumNumber(tr.BA11,tr.BA12,tr.BA13,tr.BA14,tr.BA15) }}</p>
                  </vs-td>
                  <vs-td>
                    <feather-icon icon="EditIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current"
                                  @click.stop="editData(tr, 'bill')"/>
                    <feather-icon
                      icon="CalendarIcon"
                      svgClasses="w-5 h-5 hover:text-primary stroke-current"
                      @click.stop="editData(tr, 'date')"
                    />
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </vx-card>
        </vs-tab>
        <vs-tab label="ตรวจสอบมิเตอร์คงที่" class="p-0">
          <vx-card class="mb-8 mt-6" title="เลือกรายการ">
            <div class="vx-row">
              <div class="vx-col sm:w-1/3 xs:w-1/1 w-full">
                <div class="vx-row">
                  <div class="vx-col sm:w-1/3 w-full">
                    <span>เส้นทางจัดเก็บ:</span>
                  </div>
                  <div class="vx-col sm:w-2/3 w-full">
                    <v-select :options="routes" :clearable="false" v-model="selectedRouteTab1" class="mb-4 md:mb-0"/>
                  </div>
                </div>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <div class="vx-row">
                  <div class="vx-col sm:w-1/2 w-full">
                    <vs-button color="primary" type="filled" @click="submitForm()">ตรวจสอบ</vs-button>
                  </div>
                  <div class="vx-col sm:w-1/2 w-full text-right">
                    <PDFReport :tab="'1'" :org="org" :unit="'0'" :billConfig="selectedBillConfig"
                               :route="selectedRouteTab1" :user="users"/>
                  </div>
                </div>
              </div>
            </div>
            <vs-table noDataText="ไม่พบข้อมูล" :data="users" class="table-custom mt-5">
              <template slot="thead">
                <vs-th>ลำดับที่</vs-th>
                <vs-th>รหัสสมาชิก</vs-th>
                <vs-th>ชื่อสกุล</vs-th>
                <vs-th>พื้นที่</vs-th>
                <vs-th>จำนวนหน่วยที่ใช้</vs-th>
                <vs-th>เลขที่ใบแจ้งหนี้</vs-th>
              </template>
              <template slot-scope="{data}">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                  <vs-td>
                    <p class="product-name font-medium">{{ indextr + 1 }}</p>
                  </vs-td>
                  <vs-td>
                    <p class="product-name font-medium">{{ tr.B03 }}</p>
                  </vs-td>
                  <vs-td>
                    <p class="product-name font-medium">{{ tr.M04 }}</p>
                  </vs-td>
                  <vs-td>
                    <p class="product-name font-medium">{{ selectedRouteTab1.label }}</p>
                  </vs-td>
                  <vs-td>
                    <p class="product-name font-medium">{{ tr.B21 }}</p>
                  </vs-td>
                  <vs-td>
                    <p class="product-name font-medium">{{ tr.B22 }}</p>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </vx-card>
        </vs-tab>
        <vs-tab label="ตรวจสอบค่าน้ำสูง-ต่ำ 30%" class="p-0">
          <vx-card class="mb-8 mt-6" title="เลือกรายการ">
            <div class="vx-row">
              <div class="vx-col sm:w-1/3 xs:w-1/1 w-full">
                <div class="vx-row">
                  <div class="vx-col sm:w-1/3 w-full">
                    <span>เส้นทางจัดเก็บ:</span>
                  </div>
                  <div class="vx-col sm:w-2/3 w-full">
                    <v-select :options="routes" :clearable="false" v-model="selectedRouteTab2" class="mb-4 md:mb-0"/>
                  </div>
                </div>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <div class="vx-row">
                  <div class="vx-col sm:w-1/2 w-full">
                    <vs-button color="primary" type="filled" @click="submitForm()">ตรวจสอบ</vs-button>
                  </div>
                  <div class="vx-col sm:w-1/2 w-full text-right">
                    <PDFReport :tab="'2'" :org="org" :unit="'0'" :billConfig="selectedBillConfig"
                               :route="selectedRouteTab2" :user="users"/>
                  </div>
                </div>
              </div>
            </div>
            <vs-table noDataText="ไม่พบข้อมูล" :data="users" class="table-custom mt-5">
              <template slot="thead">
                <vs-th>ลำดับที่</vs-th>
                <vs-th>รหัสสมาชิก</vs-th>
                <vs-th>ชื่อสกุล</vs-th>
                <vs-th>โทรศัพท์</vs-th>
                <vs-th>หน่วยใช้เดือนก่อน</vs-th>
                <vs-th>หน่วยใช้เดือนนี้</vs-th>
                <vs-th>
                  + / -
                </vs-th>
              </template>
              <template slot-scope="{data}">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                  <vs-td>
                    <p class="product-name font-medium">{{ indextr + 1 }}</p>
                  </vs-td>
                  <vs-td>
                    <p class="product-name font-medium">{{ tr.B03 }}</p>
                  </vs-td>
                  <vs-td>
                    <p class="product-name font-medium">{{ tr.M04 }}</p>
                  </vs-td>
                  <vs-td>
                    <p class="product-name font-medium">{{ tr.M07 }}</p>
                  </vs-td>
                  <vs-td>
                    <p class="product-name font-medium">{{ tr.previousB21 }}</p>
                  </vs-td>
                  <vs-td>
                    <p class="product-name font-medium">{{ tr.B21 }}</p>
                  </vs-td>
                  <vs-td>
                    <p class="product-name font-medium">{{ tr.differenceText }}</p>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </vx-card>
        </vs-tab>
      </vs-tabs>
    </div>
  </div>
</template>

<script>
  import PDFBillReport from "../pdf/BillReport.vue";
  import vSelect from "vue-select";
  import PDFReport from "../pdf/CheckMeterReport.vue";
  import moduleBillConfig from "@/store/billconfig/moduleBillConfig.js";
  import moduleRoute from "@/store/route/moduleRoute.js";
  import moduleBookList from "@/store/booklist/moduleBookList.js";
  import DataViewSidebar from './CheckViewSidebar.vue';
  import moduleConfig from "@/store/config/moduleConfig.js";
  import CheckPrapaMeterReport from "../pdf/CheckPrapaMeterReport.vue";
  import * as XLSX from "xlsx";
  import moment from "moment";

  export default {
    components: {
      vSelect,
      PDFBillReport,
      PDFReport,
      CheckPrapaMeterReport,
      DataViewSidebar
    },
    data() {
      return {
        type: '',
        printable: false,
        unit: 60,
        inputNumber: '',
        selectedRouteTab0Btn01: {label: '', value: ''},
        selectedRouteTab0Btn02: {label: '', value: ''},
        selectedRouteTab0: {label: '', value: ''},
        selectedRouteTab1: {label: '', value: ''},
        selectedRouteTab2: {label: '', value: ''},
        selectedBillConfig: {label: '', value: '', data: ''},
        users: [],
        year: '',
        yearTh: '',
        tabActive: 0,
        sidebarData: {},
        addNewDataSidebar: false,
        disable01: false,
        disable02: true,
        radioBtn: '01',
        imgSignBase64: '',
        imgLogoBase64: '',
        baseUrl: 'http://188.166.177.25/',
        notice:'***ท่านจะต้องตรวจสอบค่าน้ำสูงผิดปกติทุกเส้นทาง ก่อนกำหนดรอบขึ้นเดือนใหม่ เสมอ เพราะเมื่อเปลี่ยนรอบแล้วจะแก้ไขยาก***'
      }
    },
    computed: {
      org() {
        return this.$store.state.config.item;
      },
      billConfigs() {
        let options = [];
        this.$store.state.billConfigs.items.forEach(function (item) {
          options.push({
            label: item.CB01  + ' (' + item.CB04+ ')',
            value: item.CB01,
            data: item
          })
        });
        return options;
      },
      routes() {
        let options = [];
        options.push({
          label: 'ทั้งหมด',
          value: '',
        })
        this.$store.state.route.items.forEach(function (item) {
          options.push({
            label: item.R02,
            value: item.R01,
          })
        });
        return options;
      }
    },
    watch: {
      tabActive(val) {
        const payload = {};
        if (val === 0) {
          payload.B01 = this.selectedBillConfig.value;
          if (this.radioBtn === '01') {
            payload.M02 = this.selectedRouteTab0Btn01.value;
            payload.B21 = this.unit;
            this.selectedRouteTab0 = this.selectedRouteTab0Btn01;
          } else {
            payload.B03 = this.inputNumber;
            payload.M02 = this.selectedRouteTab0Btn02.value;
            payload.B21 = 0;
            this.selectedRouteTab0 = this.selectedRouteTab0Btn02;
          }
          this.getBookListsValidate(payload);
        } else if (val === 1) {
          payload.B01 = this.selectedBillConfig.value;
          payload.M02 = this.selectedRouteTab1.value;
          this.getBookListsStable(payload);
        } else {
          payload.B01 = this.selectedBillConfig.value;
          payload.M02 = this.selectedRouteTab2.value;
          this.getBookListsPercent(payload);
        }
      },
      radioBtn: {
        handler(val) {
          if (val === '01') {
            this.disable01 = false;
            this.disable02 = true;
            this.selectedRouteTab0 = { label : 'ทั้งหมด' , value: '' };
          }
          if (val === '02') {
            this.disable01 = true;
            this.disable02 = false;
            this.selectedRouteTab0Btn02 = { label : 'ทั้งหมด' , value: '' };
          }
        },
        deep: true
      },
    },
    methods: {
      diffCal(diff) {
        if (diff > 0) {
          return '+' + diff
        } else {
          return diff
        }
      },
      async init() {
        const bill = await this.$store.state.billConfigs.items[0];
        const route = await this.$store.state.route.items[0];
        this.selectedBillConfig = {
          label: bill.CB01  + ' (' + bill.CB04+ ')',
          value: bill.CB01,
          data: bill
        };
        this.year = bill.CB03;
        this.yearTh = bill.CB04;
        this.selectedRouteTab0Btn01 = {
          label: 'ทั้งหมด',
          value: ''
        };
        this.selectedRouteTab0Btn02 = {
          label: 'ทั้งหมด',
          value: ''
        };
        this.selectedRouteTab0 = {
          label: route.R02,
          value: route.R01
        };
        this.selectedRouteTab1 = {
          label: route.R02,
          value: route.R01
        };
        this.selectedRouteTab2 = {
          label: route.R02,
          value: route.R01
        };
        const payload = {
          B01: bill.CB01,
          M02: route.R01,
          B21: this.unit
        };
        this.selectedRouteTab0 = this.selectedRouteTab0Btn01;
        this.getBookListsValidate(payload);
        const url = await this.$store.state.config.item.report_image_url;
        if(url !== '' && url !== undefined && url !== null) {
          this.imgSignBase64 = await this.getDataUri(this.baseUrl + url);
        }
        const logoUrl = await this.$store.state.config.item.logo_image_url;
        if(logoUrl !== '' && logoUrl !== undefined && logoUrl !== null) {
          this.imgLogoBase64 = await this.getDataUri(this.baseUrl + logoUrl);
        }
      },
      getDataUri(url) {
        return new Promise(resolve => {
          const image = new Image();
          image.setAttribute('crossOrigin', 'anonymous');
          image.onload = function () {
            const canvas = document.createElement('canvas');
            canvas.width = this.width;
            canvas.height = this.height;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(this, 0, 0);
            const dataURL = canvas.toDataURL("image/png");
            resolve(canvas.toDataURL("image/png"));
          };
          image.src = url;
        })
      },
      submitForm() {
        const payload = {};
        if (this.tabActive === 0) {
          payload.B01 = this.selectedBillConfig.value;
          if (this.radioBtn === '01') {
            payload.M02 = this.selectedRouteTab0Btn01.value;
            payload.B21 = this.unit;
            this.selectedRouteTab0 = this.selectedRouteTab0Btn01;
          } else {
            if (this.inputNumber === '') {
              this.$vs.dialog({
                type: 'alert',
                color: 'warning',
                title: `คำเตือน`,
                text: 'กรุณากรอกเลขทะเบียนผู้ใช้น้ำ',
                acceptText: 'ปิด'
              });
              return;
            }
            payload.B03 = this.inputNumber;
            payload.M02 = this.selectedRouteTab0Btn02.value;
            payload.B21 = 0;
            this.selectedRouteTab0 = this.selectedRouteTab0Btn02;
          }
          this.$vs.loading();
          this.getBookListsValidate(payload);
        } else if (this.tabActive === 1) {
          payload.B01 = this.selectedBillConfig.value;
          payload.M02 = this.selectedRouteTab1.value;
          this.$vs.loading();
          this.getBookListsStable(payload);
        } else {
          payload.B01 = this.selectedBillConfig.value;
          payload.M02 = this.selectedRouteTab2.value;
          this.$vs.loading();
          this.getBookListsPercent(payload);
        }
      },
      setNumber(value) {
        const number = parseFloat(value);
        return number.toFixed(2);
      },
      setSumNumber(value1, value2, value3, value4, value5) {
        const number = parseFloat(value1) + parseFloat(value2) + parseFloat(value3) + parseFloat(value4) + parseFloat(value5);
        return this.round(number, 2);
      },
      setBillConfig(item) {
        this.year = item.data.CB03;
        this.yearTh = item.data.CB04;
        const payload = {};
        if (this.tabActive === 0) {
          payload.B01 = this.selectedBillConfig.value;
          if (this.radioBtn === '01') {
            payload.M02 = this.selectedRouteTab0Btn01.value;
            payload.B21 = this.unit;
            this.selectedRouteTab0 = this.selectedRouteTab0Btn01;
          } else {
            if (this.inputNumber === '') {
              this.$vs.dialog({
                type: 'alert',
                color: 'warning',
                title: `คำเตือน`,
                text: 'กรุณากรอกเลขทะเบียนผู้ใช้น้ำ',
                acceptText: 'ปิด'
              });
              return;
            }
            payload.B03 = this.inputNumber;
            payload.M02 = this.selectedRouteTab0Btn02.value;
            payload.B21 = 0;
            this.selectedRouteTab0 = this.selectedRouteTab0Btn02;
          }
          this.$vs.loading();
          this.getBookListsValidate(payload);
        } else if (this.tabActive === 1) {
          payload.B01 = item.label;
          payload.M02 = this.selectedRouteTab1.value;
          this.$vs.loading();
          this.getBookListsStable(payload);
        } else {
          payload.B01 = this.selectedBillConfig.value;
          payload.M02 = this.selectedRouteTab2.value;
          this.$vs.loading();
          this.getBookListsPercent(payload);
        }
      },
      editData(data, type) {
        this.sidebarData = data;
        this.type = type;
        this.toggleDataSidebar(true);
      },
      toggleDataSidebar(val = false) {
        this.addNewDataSidebar = val
      },
      formatTextDate(text){
        if(text !== '' && text !== undefined && text !== 'Invalid date'){
          try {
            const date = text.split('/');
            let year = ''
            if(date[2].toString().length === 11)
              year = parseInt(date[2]) + 2543;
            else
              year = parseInt(date[2]) + 543;
            return date[0] + '/' + date[1] + '/' + year;
          }catch (e) {
            return '-'
          }
        }else{
          return '-'
        }
      },
      setDateFormat(value) {
        try {
          const dateNow = moment(value).format("DD/MM/YYYY");
          const dateSplit = dateNow.split("/");
          const year = parseInt(dateSplit[2]) + 543;
          const date = dateSplit[0] + "/" + dateSplit[1] + "/" + year;
          return date;
        }catch (e) {
          return value
        }
      },
      formatBill(value) {
        let bill = this.$store.state.billConfigs.items.filter((item) => {
          return item.CB01 === value
        })
        return bill[0].CB04;
      },
      async getBookListsValidate(payload) {
        this.$vs.loading();
        await this.$store.dispatch("bookLists/getBookListsValidate", payload).then((res) => {
          if (res.status === 200) {
            let response = res.data;
            let array = [];
            for (let i = 0; i < response.length; i++) {
              let data = response[i];
              if(response[i].B24 !== undefined)
              data.B24 = this.setDateFormat(response[i].B24)
              data.B17 = this.formatTextDate(response[i].B17)
              data.B19 = this.formatTextDate(response[i].B19)
              data.sum = response[i].sum.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })
              data.CB04 = this.formatBill(response[i].B01)
              data.Org01 = this.org.Org01
              data.Org02 = this.org.Org02
              data.Org03 = this.org.Org03
              data.Org04 = this.org.Org04
              data.Org05 = this.org.Org05
              array.push(data)
            }
            this.users = array;
            this.printable = this.org.org_id === '200136' && this.unit === '0'
            this.$vs.loading.close();
          } else {
            this.users = [];
            this.$vs.loading.close();
          }
        }).catch((error) => {
          this.users = [];
          this.$vs.loading.close();
        })
      },
      async getBookListsStable(payload) {
        this.$vs.loading();
        await this.$store.dispatch("bookLists/getBookListsStable", payload).then((res) => {
          if (res.status === 200) {
            this.users = res.data;
            this.$vs.loading.close();
          } else {
            this.users = [];
            this.$vs.loading.close();
          }
        }).catch((error) => {
          this.users = [];
          this.$vs.loading.close();
        })
      },
      async getBookListsPercent(payload) {
        this.$vs.loading();
        await this.$store.dispatch("bookLists/getBookListsPercent", payload).then((res) => {
          if (res.status === 200) {
            this.users = res.data;
            let arr = [];
            res.data.forEach(function (item) {
              let data = {};
              data = item;
              data.difference = item.B21 - item.previousB21;
              let diffText = ''
              if(item.B21 - item.previousB21 > 0){
                diffText = '+' + (item.B21 - item.previousB21).toString()
              }else{
                diffText = item.B21 - item.previousB21
              }
              data.differenceText = diffText;
              arr.push(data);
            });
            arr.sort(function (a, b) {
              return b.difference - a.difference
            });
            this.users = arr;
            this.$vs.loading.close();
          } else {
            this.users = [];
            this.$vs.loading.close();
          }
        }).catch((error) => {
          this.users = [];
          this.$vs.loading.close();
        })
      },
      async getBookListsExcel() {
        this.$vs.loading();
        let payload = {
          M02: this.selectedRouteTab0.value,
          B01: this.selectedBillConfig.value
        };
        await this.$store.dispatch("bookLists/fetchDebtDataListItems", payload).then((res) => {
          if (res.status === 200 && res.data.length !== 0) {
            const dataExcel = res.data;
            this.excel(dataExcel);
            this.$vs.loading.close();
          } else {
            this.warningDialog();
            this.$vs.loading.close();
          }
        }).catch((error) => {
          this.warningDialog();
          this.$vs.loading.close();
        });
      },
      round(value, decimals) {
        return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
      },
      excel(data) {
        let sumPrapa = 0;
        let sumService = 0;
        let sumVat = 0;
        let sum = 0;
        let itemsFormatted = [];
        for (let i = 0; i < data.length; i++) {
          let BA11 = 0;
          let BA12 = 0;
          let BA13 = 0;
          let BA14 = 0;
          let BA15 = 0;
          let sumBA = 0;
          if (data[i].BA11 !== undefined && data[i].BA11 !== null && data[i].BA11 !== '' && data[i].BA11 !== 'NaN') {
            BA11 = parseFloat(data[i].BA11);
          }
          if (data[i].BA12 !== undefined && data[i].BA12 !== null && data[i].BA12 !== '' && data[i].BA12 !== 'NaN') {
            BA12 = parseFloat(data[i].BA12);
          }
          if (data[i].BA13 !== undefined && data[i].BA13 !== null && data[i].BA13 !== '' && data[i].BA13 !== 'NaN') {
            BA13 = parseFloat(data[i].BA13);
          }
          if (data[i].BA14 !== undefined && data[i].BA14 !== null && data[i].BA14 !== '' && data[i].BA14 !== 'NaN') {
            BA14 = parseFloat(data[i].BA14);
          }
          if (data[i].BA15 !== undefined && data[i].BA15 !== null && data[i].BA15 !== '' && data[i].BA15 !== 'NaN') {
            BA15 = parseFloat(data[i].BA15);
          }
          BA11 = this.round(BA11, 2);
          BA12 = this.round(BA12, 2);
          BA13 = this.round(BA13, 2);
          BA14 = this.round(BA14, 2);
          BA15 = this.round(BA15, 2);
          sumPrapa += BA11;
          sumService += BA12;
          sumVat += BA13;
          sumBA = BA11 + BA12 + BA13 + BA14 + BA15;
          sum += sumBA;
          itemsFormatted.push({
            'ลำดับที่': i + 1,
            'รหัส': data[i].B03,
            'ชื่อสกุลสมาชิกที่ใช้น้ำ': data[i].member_name,
            'หมายเลขมาตร': data[i].member_meter_no,
            'ขนาดมาตร': data[i].member_meter_size,
            'สูตร': data[i].member_meter_formular,
            'จดครั้งแรก': data[i].B18,
            'จดครั้งหลัง': data[i].B20,
            'จำนวนหน่วย': data[i].B21,
            'ค่าน้ำ': BA11.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            'ค่าบริการ': BA12.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            'ภาษี': BA13.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            'รวมเงิน': this.round(sumBA, 2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          });
        }
        itemsFormatted.push({
          'ลำดับที่': '',
          'รหัส': '',
          'ชื่อสกุลสมาชิกที่ใช้น้ำ': '',
          'หมายเลขมาตร': '',
          'ขนาดมาตร': '',
          'สูตร': '',
          'จดครั้งแรก': '',
          'จดครั้งหลัง': '',
          'จำนวนหน่วย': '',
          'ค่าน้ำ': this.round(sumPrapa, 2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
          'ค่าบริการ': this.round(sumService, 2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
          'ภาษี': this.round(sumVat, 2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
          'รวมเงิน': this.round(sum, 2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        });
        let fileName = 'รายงานตรวจสอบจดมิเตอร์.xlsx';
        let ws = XLSX.utils.json_to_sheet(itemsFormatted);
        let wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "People");
        XLSX.writeFile(wb, fileName);
      },
      warningDialog() {
        this.$vs.dialog({
          type: 'alert',
          color: 'warning',
          title: `คำเตือน`,
          text: 'ไม่มีรายชื่อในรายงาน',
          acceptText: 'ปิด'
        })
      },
    },
    async created() {
      this.$vs.loading();
      if (!moduleRoute.isRegistered) {
        this.$store.registerModule('route', moduleRoute);
        moduleRoute.isRegistered = true;
      }
      if (!moduleBillConfig.isRegistered) {
        this.$store.registerModule('billConfigs', moduleBillConfig)
        moduleBillConfig.isRegistered = true;
      }
      if (!moduleBookList.isRegistered) {
        this.$store.registerModule('bookLists', moduleBookList);
        moduleBookList.isRegistered = true;
      }
      if (!moduleConfig.isRegistered) {
        this.$store.registerModule('config', moduleConfig)
        moduleConfig.isRegistered = true;
      }
      await this.$store.dispatch("config/fetchDataItem");
      await this.$store.dispatch("route/fetchDataListItems");
      await this.$store.dispatch("billConfigs/getDataListMenuItems");
      this.init();
    },
  }
</script>

<style lang="scss">
  .vs-button:not(.vs-radius):not(.includeIconOnly):not(.small):not(.large).btn-export {
    /*padding: .75rem 3.35rem;*/
    width: 100%;
  }

  .table-custom .vs-con-tbody .vs-table--tbody-table .vs-table--thead th {
    background: #8794a2;
    color: #FFFFFF;
    font-size: 14px;
    font-weight: bold;
  }

  .text-position {
    text-align: right;
  }

  .input-water {
    width: 50% !important;
  }

  .v-select.area-radio1 ul.vs__dropdown-menu {
    max-height: 300px;
  }

  .v-select.area-radio2 ul.vs__dropdown-menu {
    max-height: 230px;
  }

  @media screen and (max-width: 768px) {
    .input-water {
      width: 42% !important;
    }
    .vs-button:not(.vs-radius):not(.includeIconOnly):not(.small):not(.large).btn-check {
      padding: .75rem 1rem;
    }
    .w-btn-check {
      width: 33% !important;
    }
  }

  @media screen and (max-width: 420px) {
    .input-water {
      width: 100% !important;
    }
    .vs-button:not(.vs-radius):not(.includeIconOnly):not(.small):not(.large).btn-check {
      padding: .75rem 2.35rem;
    }
    .vs-button:not(.vs-radius):not(.includeIconOnly):not(.small):not(.large).vs-button-border.btn-edit {
      padding: .75rem 1rem;
    }
    .text-position {
      text-align: center;
    }
    .w-btn-check {
      margin-top: 20px;
      width: 100% !important;
    }
  }

  .input-unit {
    width: 100px;
  }
</style>
