<template>
  <vs-button @click="checkData()" color="primary" type="border" class="mb-4 md:mb-0">พิมพ์รายงาน</vs-button>
</template>

<script>
  import jsPDF from "jspdf";
  import 'jspdf-autotable'
  const fs = require('fs');
  import { font } from "./assets/THSarabunNew-normal";
  import { font_bold } from "./assets/THSarabunNew-bold";
  import moment from "moment";

  export default {
    components: {
      jsPDF,
    },
    props: ['tab', 'org', 'unit', 'billConfig', 'route', 'user'],
    name: "CheckMeterForm",
    data() {
      return {

      }
    },
    methods: {
      checkData() {
        if (this.user.length === 0) {
          this.$vs.dialog({
            type: 'alert',
            color: 'warning',
            title: `คำเตือน`,
            text: 'ไม่มีบัญชีที่ตรวจสอบ',
            acceptText: 'ปิด'
          })
        } else {
          this.generatePDF();
        }
      },
      generatePDF() {
        let fileName = 'รายงานค่าน้ำสูงผิดปกติ';
        const doc = new jsPDF({format: [279, 210], unit:'mm', orientation: 'portrait',});
        const tab = this.tab;
        const org = this.org;
        const unit = this.unit;
        const billConfig = this.billConfig;
        const route = this.route;
        const user = this.user;
        let array = [];
        let header = [];
        if(tab === '2') {
          header = [['ลำดับที่', 'รหัสผู้ใช้น้ำ', 'ชื่อสกุล', 'โทรศัพท์', 'หน่วยใช้เดือนก่อน', 'หน่วยใช้เดือนนี้', '+ / -']]
        } else {
          header = [['พื้นที่', 'ลำดับที่', 'รหัสผู้ใช้น้ำ', 'ชื่อสกุล', 'มิเตอร์จดก่อน', 'มิเตอร์จดหลัง', 'จำนวนที่ใช้']]
        }
        for(let i = 0; i < user.length; i++) {
          let area = ' ';
          if (i === 0) {
            area = route.label;
          }
          if(tab === '2') {
            array.push([i + 1, user[i].B03, user[i].M04, user[i].M07, user[i].previousB21, user[i].B21, user[i].differenceText]);
          } else {
            array.push([area, i + 1, user[i].B03, user[i].M04, user[i].B18, user[i].B20, user[i].B21]);
          }
        }
        doc.addFileToVFS('THSarabunNew-normal.ttf', font);
        doc.addFileToVFS('THSarabunNew-bold.ttf', font_bold);
        doc.addFont('THSarabunNew-normal.ttf', 'THSarabunNew', 'normal');
        doc.addFont('THSarabunNew-bold.ttf', 'THSarabunNew', 'bold');
        const totalPagesExp = "{total_pages_count_string}";
        doc.autoTable({
          styles: { font: 'THSarabunNew', fontSize: 14 },
          tableWidth: 198,
          margin: {left: 8, top: 45, bottom: 8},
          theme: 'plain',
          head: header,
          body: array,
          didDrawPage: function(data) {
            doc.setFont('THSarabunNew','bold');
            doc.setFontSize(18);
            doc.text(org.Org01, doc.internal.pageSize.getWidth()/2, 10, {align: 'center'});
            doc.text(org.Org03, doc.internal.pageSize.getWidth()/2, 18, {align: 'center'});
            doc.setFontSize(16);
            let textTab;
            if (tab === '2') {
              textTab = 'รายงานแสดงผู้ที่ใช้น้ำสูง-ต่ำ 30%'
            } else {
              textTab = 'รายงานแสดงผู้ที่ใช้น้ำตั้งแต่ ' + unit + ' หน่วย';
            }
            doc.text(textTab + ' ประจำเดือน ' + billConfig.data.CB04 + ' (' + billConfig.data.CB01 + ')', 10, 28);
            doc.text('เฉพาะเส้นทางจัดเก็บที่ ' + route.label, 10, 34);
            doc.setFont('THSarabunNew','normal');
            doc.setFontSize(14);
            doc.text(moment().format('DD/MM/YYYY'), 10, 43);
            // let footerStr = "Page " + doc.internal.getNumberOfPages();
            // if (typeof doc.putTotalPages === 'function') {
            //   doc.putTotalPages(totalPagesExp);
            //   footerStr = footerStr + " of " + totalPagesExp;
            // }
            // doc.text(footerStr,  200, 43, {align: 'right'});
            // var totalPagesExp = "{total_pages_count_string}";
            // const pageCount = doc.internal.getNumberOfPages();
            // for(let i = 0; i < pageCount; i++) {
            //   doc.putTotalPages(totalPagesExp);
            // }
            // doc.text('Page ' + doc.internal.getCurrentPageInfo().pageNumber + " of " + totalPagesExp, 200, 43, {align: 'right'});
          }
        });
        // if (typeof doc.putTotalPages === 'function') {
        //   doc.putTotalPages(totalPagesExp);
        // }
        let name;
        if(tab === '0') {
          name = 'รายงานค่าน้ำสูงผิดปกติ-';
        } else if (tab === '1') {
          name = 'รายงานมิเตอร์คงที่-';
        } else {
          name = 'รายงานค่าน้ำสูง-ต่ำ30%-'
        }
        doc.save(name + moment().format('DD-MM-YYYY') + '.pdf');
      }
    }
  }
</script>
