<template>
  <vs-button @click="getBookLists()" color="primary" type="border" class="mb-4 md:mb-0">พิมพ์รายงานตรวจสอบจดมิเตอร์</vs-button>
</template>
<script>
  import 'jspdf-autotable'
  import jsPDF from "jspdf";
  import {font} from "./assets/THSarabunNew-normal";
  import {font_bold} from "./assets/THSarabunNew-bold";
  import moment from "moment";
  import moduleBookList from "@/store/booklist/moduleBookList.js";

  export default {
    props: ['billConfig', 'area', 'org'],
    components: {
      jsPDF,
    },
    name: "P17PrapaReport",
    data() {
      return {
        thmonth: ['', 'มกราคม', 'กุมภาพันธ์', 'มีนาคม',
          'เมษายน', 'พฤษภาคม', 'มิถุนายน', 'กรกฎาคม', 'สิงหาคม', 'กันยายน',
          'ตุลาคม', 'พฤศจิกายน', 'ธันวาคม'],
      }
    },
    methods: {
      currentThaiDate() {
        const date = moment().format('DD/MM/YYYY');
        const dateSplit = date.split('/');
        const year = parseInt(dateSplit[2]) + 543;
        return dateSplit[0] + '/' + dateSplit[1] + '/' + year;
      },
      warningDialog() {
        this.$vs.dialog({
          type: 'alert',
          color: 'warning',
          title: `คำเตือน`,
          text: 'ไม่มีรายชื่อในรายงาน',
          acceptText: 'ปิด'
        })
      },
      async getBookLists() {
        this.$vs.loading();
        let payload = {
            M02: this.area.value,
            B01: this.billConfig.value
        };
        await this.$store.dispatch("bookLists/fetchDebtDataListItems", payload).then((res) => {
          if (res.status === 200 && res.data.length !== 0) {
            const booklists = res.data;
            this.generatePDF(booklists);
            this.$vs.loading.close();
          } else {
            this.warningDialog();
            this.$vs.loading.close();
          }
        }).catch((error) => {
          this.warningDialog();
          this.$vs.loading.close();
        });
      },
      generatePDF(bookLists) {
        const doc = new jsPDF({format: [210, 330], unit:'mm', orientation: 'landscape',});
        const org  = this.org;
        const district = org.Org01;
        const address = org.Org03;
        const month = bookLists[0].CB04;
        const id_bill = bookLists[0].B01;
        const area = this.area;
        const date = this.currentThaiDate()
        let sumUnit = 0;
        let sumPrapa = 0;
        let sumService = 0;
        let sumVat = 0;
        let sum = 0;
        let array = [];
        for (let i = 0; i < bookLists.length; i++) {
          const data = bookLists[i];
          let BA11 = 0;
          let BA12 = 0;
          let BA13 = 0;
          let BA14 = 0;
          let BA15 = 0;
          let sumBA = 0;
          if (data.BA11 !== undefined && data.BA11 !== null && data.BA11 !== '' && data.BA11 !== 'NaN') {
            BA11 = parseFloat(data.BA11);
            sumPrapa += parseFloat(data.BA11);
          }
          if (data.BA12 !== undefined && data.BA12 !== null && data.BA12 !== '' && data.BA12 !== 'NaN') {
            BA12 = parseFloat(data.BA12);
            sumService += parseFloat(data.BA12);
          }
          if (data.BA13 !== undefined && data.BA13 !== null && data.BA13 !== '' && data.BA13 !== 'NaN') {
            BA13 = parseFloat(data.BA13);
            sumVat += parseFloat(data.BA13);
          }
          if (data.BA14 !== undefined && data.BA14 !== null && data.BA14 !== '' && data.BA14 !== 'NaN') {
            BA14 = parseFloat(data.BA14);
          }
          if (data.BA15 !== undefined && data.BA15 !== null && data.BA15 !== '' && data.BA15 !== 'NaN') {
            BA15 = parseFloat(data.BA15);
          }
          if (data.B21 !== undefined && data.B21 !== null && data.B21 !== '' && data.B21 !== 'NaN') {
            sumUnit += parseInt(data.B21);
          }
          sumBA = BA11 + BA12 + BA13 + BA14 + BA15;
          sum += sumBA;
          const dateNow = moment(data.B24).format('DD/MM/YYYY');
          const dateSplit = dateNow.split('/');
          const year = parseInt(dateSplit[2]) + 543;
          const date = dateSplit[1] + ' ' + this.thmonth[parseInt(dateSplit[0])] + ' ' + year;
          array.push([
            i + 1,
            data.B03,
            data.member_name,
            data.member_meter_no,
            data.member_meter_size,
            data.member_meter_formular,
            data.B18,
            data.B20,
            data.B21,
            BA11.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            BA12.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            BA13.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            sumBA.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          ]);

        }
        array.push([
          'ผลรวมของ',
          { content: area.label, colSpan: 7 },
          sumUnit,
          sumPrapa.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
          sumService.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
          sumVat.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
          sum.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        ]);
        var totalPagesExp = '{total_pages_count_string}';
        doc.addFileToVFS('THSarabunNew-normal.ttf', font);
        doc.addFileToVFS('THSarabunNew-bold.ttf', font_bold);
        doc.addFont('THSarabunNew-normal.ttf', 'THSarabunNew', 'normal');
        doc.addFont('THSarabunNew-bold.ttf', 'THSarabunNew', 'bold');
        doc.autoTable({
          showHead: 'everyPage',
          showFoot: 'everyPage',
          styles: {font: 'THSarabunNew', fontSize: 9, textColor: 0},
          margin: {left: 5, top: 35, right: 5},
          headStyles: {fillColor: [255, 255, 255], textColor: 0, lineColor: 180, lineWidth: 0.1},
          columnStyles: {
            0: {halign: 'right'},
            4: {halign: 'center'},
            5: {halign: 'center'},
            6: {halign: 'center'},
            7: {halign: 'center'},
            8: {halign: 'right'},
            9: {halign: 'right'},
            10: {halign: 'right'},
            11: {halign: 'right'},
            13: {halign: 'right'}
          },
          theme: 'grid',
          head: [
            [
              'ลำดับที่',
              'รหัส',
              'ชื่อสกุลสมาชิกที่ใช้น้ำ',
              'หมายเลขมาตร',
              'ขนาดมาตร',
              'สูตร',
              'จดครั้งแรก',
              'จดครั้งหลัง',
              'จำนวนหน่วย',
              'ค่าน้ำ',
              'ค่าบริการ',
              'ภาษี',
              'รวมเงิน',
            ]
          ],
          body: array,
          didDrawPage: function (data) {
            // Header
            doc.setFont('THSarabunNew', 'bold');
            doc.setFontSize(16);
            doc.text(district, doc.internal.pageSize.getWidth() / 2, 10, {align: 'center'});
            doc.text(address, doc.internal.pageSize.getWidth() / 2, 16, {align: 'center'});
            doc.setFontSize(14);
            doc.text('รายงานตรวจสอบมิเตอร์', doc.internal.pageSize.getWidth() / 2, 25, {align: 'center'});
            doc.text('ประจำเดือน ' + month + ' (' + id_bill + ') เขต '+ area.value, doc.internal.pageSize.getWidth() / 2, 30, {align: 'center'});
            doc.setFont('THSarabunNew', 'normal');
            //footer
            var str = 'Page ' + doc.internal.getNumberOfPages();
            if (typeof doc.putTotalPages === 'function') {
              str = str + ' of ' + totalPagesExp
            }
            doc.setFontSize(12);
            var pageSize = doc.internal.pageSize;
            var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
            doc.text('วันที่พิมพ์ ' + date, 8, pageHeight - 8,);
            // doc.text('หน้าที่พิมพ์ ' + str, 288, pageHeight - 8, {align: 'right'});
          },
        });
        if (typeof doc.putTotalPages === 'function') {
          doc.putTotalPages(totalPagesExp);
        }
        doc.save(`รายงานตรวจสอบจดมิเตอร์` + moment().format('DD-MM-YYYY HH:MM:SS') + '.pdf');
      }
    },
    created() {
      if (!moduleBookList.isRegistered) {
        this.$store.registerModule('bookLists', moduleBookList);
        moduleBookList.isRegistered = true;
      }
    }
  }
</script>
