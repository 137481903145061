<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary" class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
    <div class="mt-6 flex items-center justify-between px-6">
        <h4>{{ Object.entries(this.data).length === 0 ? "เพิ่ม" : "แก้ไข" }} ข้อมูล</h4>
        <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <component :is="scrollbarTag" class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">

      <div v-if="type === 'bill'" class="p-6">
        <vs-input label="จดก่อน" type="number" v-model="B18" class="mt-5 w-full" name="bill" v-validate="'required'" />
        <span class="text-danger text-sm" v-show="errors.has('bill')">กรุณากรอกเลขทีจดก่อน</span>
      </div>
      <div v-if="type === 'bill'" class="p-6 pt-0">
        <vs-input label="จดหลัง" type="number" v-model="B20" class="mt-5 w-full" name="bill" v-validate="'required'" />
        <span class="text-danger text-sm" v-show="errors.has('bill')">กรุณากรอกเลขทีจดหลัง</span>
      </div>

      <div v-if="type === 'date'" class="p-6 pt-0 pb-0">
        <div class="mt-5 w-full">
          <p class="text-sm mt-5 w-full">วันจดก่อน</p>
          <datepicker
            :language="languages[language]"
            format="dd MMMM yyyy"
            v-model="B17"
          ></datepicker>
          <span class="text-danger text-sm" v-show="errors.has('date')">กรุณาเลือกวันที่</span>
        </div>
      </div>

      <div v-if="type === 'date'" class="p-6 pt-0 pb-0">
        <div class="mt-5 w-full">
          <p class="text-sm mt-5 w-full">วันจดหลัง</p>
          <datepicker
            :language="languages[language]"
            format="dd MMMM yyyy"
            v-model="B19"
          ></datepicker>
          <span class="text-danger text-sm" v-show="errors.has('date')">กรุณาเลือกวันที่</span>
        </div>
      </div>
      <div v-if="type === 'bill'" class="p-6 pt-0">
        <vs-input label="รหัสผ่าน" type="password" v-model="password" class="mt-5 w-full" name="password" v-validate="'required'" />
        <span class="text-danger text-sm" v-show="errors.has('password')">กรุณากรอกรหัสผ่าน</span>
        <span class="text-danger text-sm" v-show="checkPassword && password !== ''">รหัสผ่านไม่ถูกต้อง</span>
      </div>
    </component>

    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button class="mr-6" @click="confirmEdit" :disabled="!isFormValid">ตกลง</vs-button>
      <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">ยกเลิก</vs-button>
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import moduleBookList from "@/store/booklist/moduleBookList.js";
import moment from "moment";
import Datepicker from "vuejs-datepicker";
import * as lang from "vuejs-datepicker/dist/locale";

export default {
  props: {
    type: {
      type: String,
      required: true
    },
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      default: () => {},
    },
    org: {
      type: Object,
      default: () => {},
    }
  },
  components: {
    Datepicker,
    VuePerfectScrollbar,
  },
  data() {
    return {
      language: "th",
      languages: lang,
      dataId: null,
      B20: '',
      B18: '',
      B17: new Date(),
      B19: new Date(),
      settings: { // perfectscrollbar settings
          maxScrollbarLength: 60,
          wheelSpeed: .60,
      },
      password: '',
      orgPassword: '',
      checkPassword: false
    }
  },
  watch: {
    isSidebarActive(val) {
      if(!val) return;
      if(Object.entries(this.data).length === 0) {
        this.initValues();
        this.$validator.reset()
      }else {
        let data = JSON.parse(JSON.stringify(this.data));
        this.orgPassword = this.org.password;
        this.dataId = data._id;
        this.B20 = data.B20;
        this.B18 = data.B18;
        const year = parseInt(data.B17.split('/')[2]) - 543
        const start = data.B17.substring(0,6)+year
        const end = data.B19.substring(0,6)+year
        this.B17 = new Date(start.split('/')[2],parseInt(start.split('/')[1]) - 1,start.split('/')[0]);
        this.B19 = new Date(end.split('/')[2],parseInt(end.split('/')[1]) - 1,end.split('/')[0]);
      }
    }
  },
  computed: {
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive
      },
      set(val) {
        if(!val) {
          this.$emit('closeSidebar')
        }
      }
    },
    isFormValid() {
      return !this.errors.any();
    },
    scrollbarTag() {
      return this.$store.getters.scrollbarTag;
    }
  },
  methods: {
    formatThaiDateToIsoDate(date){
      const year = date.split('/')[2]

    },
    initValues() {
      if(this.data.id) return;
        this.dataId = null;
        this.B18 = "";
        this.B20 = ""
        this.B17 = ""
        this.B19 = ""
    },
    submitData() {
      this.$validator.validateAll().then(result => {
          if (result) {
            this.$vs.dialog({
              type: 'confirm',
              color: 'warning',
              title: 'คำเตือน',
              text: 'คุณต้องการเแก้ไขข้อมูลใช่หรือไม่',
              acceptText: 'ต้องการ',
              cancelText: 'ยกเลิก',
              accept: this.confirmEdit
            });
          }
      })
    },
    async confirmEdit() {
      let obj = {};
      const data = JSON.parse(JSON.stringify(this.data));
      const user = JSON.parse(sessionStorage.getItem('vuex'));
      const staffId = user.owner.user.user._id;
      if(this.type === 'bill'){
        if(this.password !== this.orgPassword) {
          this.checkPassword = true;
          return;
        }
        this.checkPassword = false;
        obj = {
          B01: data.B01,
          member_id: data.member_id,
          staff_id: staffId,
          current_meter: parseInt(this.B20),
          last_meter: parseInt(this.B18),
          B10: 0
        };
        await this.$store.dispatch("bookLists/updateItemForMeter", obj).then((res) => {
          this.$emit('closeSidebar');
        });
      }  else{
        let dateB17 = moment(this.B17).format("YYYY/MM/DD");
        let dateB19 = moment(this.B19).format("YYYY/MM/DD");
        obj = {
          id: data._id,
          staff_id: staffId,
          B17: dateB17,
          B19: dateB19
        };
        await this.$store.dispatch("bookLists/updateDateItemForMeter", obj).then((res) => {
        if (res.status === 200) {
          this.password = '';
          this.$emit('closeSidebar');
        }
      });
      }
      
    }
  },
  async created() {
    if (!moduleBookList.isRegistered) {
      this.$store.registerModule('bookLists', moduleBookList);
      moduleBookList.isRegistered = true;
    }
  }
}
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
    // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
    height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

    &:not(.ps) {
      overflow-y: auto;
    }
}
</style>
